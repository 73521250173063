//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { sysFileInfoDownload } from '@/api/modular/system/fileManage'
import nodes from '@/views/fileStream/documentApproval/documentManage/nodes.vue'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import fileDownAndPreView from '@/views/fileStream/documentSend/components/fileDownAndPreView.vue'
import moment from 'moment'
export default {
  data() {
    return {
      moment,
      flow_secrecy_degree: [],
      resList: [],
      jbr_list: [],
    }
  },
  props: {
    info: {
      type: Object,
      default() {
        return {}
      },
    },
    from: {
      type: String,
      default: '',
    },
  },
  computed: {
    my_data() {
      return this.init_data().my_data
    },
    list() {
      let { list } = this.init_data()
      list = list.filter((node) => node.sort !== -1)
      return list
    },
  },
  created() {
    sysDictTypeDropDown({ code: 'flow_secrecy_degree' }).then((res) => {
      this.flow_secrecy_degree = res.data
    })
  },
  components: {
    OpenData,
    fileDownAndPreView,
    nodes,
  },
  methods: {
    Filter(s) {
      const values = this.flow_secrecy_degree.filter((item) => item.code == s)
      if (values.length > 0) {
        return values[0].value
      }
    },
    formatListMess(node) {
      const arr = []
      if (node.deptInfos && node.deptInfos.length > 0) {
        node.deptInfos.forEach((dep) => {
          dep.deptUsers.forEach((k) => {
            arr.push(k)
          })
        })
      }
      node.nodes = [...node.nodes, ...arr].filter((j) => j.userId && j.approvalFlag !== 2)
    },
    formatList(list) {
      const listCopy = JSON.parse(JSON.stringify(list))
      listCopy.forEach((j) => {
        this.formatListMess(j)
      })
      return listCopy.filter((j) => j.sort != -1 && ![99, 100].includes(j.sort))
    },
    formatTdtt(swsj) {
      if (!swsj) return ''
      return moment(swsj).format('YYYY-MM-DD')
    },
    onChange(date, dateString) {
      console.log(date, dateString)
    },
    formatApprovalTime(approvalTime) {
      if (!approvalTime) return ''
      approvalTime = approvalTime.replace(/-/g, '/')
      const date = new Date(approvalTime)
      const dateStr = date.toLocaleString()
      return dateStr.replace(/\//g, '-')
    },
    formatDraftId(id) {
      if (!id) return []
      return JSON.parse(id).map((j) => ({ ...j, id: j.userId || j.departmentId }))
    },
    // 下載當前文件
    download(record) {
      this.cardLoading = true
      sysFileInfoDownload({ id: record.fileId })
        .then((res) => {
          this.cardLoading = false
          this.editAcceptBill({ downloadStatus: 1 })
          this.downloadfile(res)
          // eslint-disable-next-line handle-callback-err
        })
        .catch(() => {
          this.cardLoading = false
          this.$message.error('下載錯誤：獲取文件流錯誤')
        })
    },
    init_data() {
      const { info } = this
      let my_data = {}
      let list = []
      if (!info.name) {
        return { my_data, list }
      }
      const {
        acceptTime,
        name,
        urgencyDegree,
        secrecyDegree,
        deviseOpinion,
        draftId,
        nodeParam,
        // approvalWay,
        tellPhone,
        summary,
        attach, //正文
        fileIds, //附件
        title,
        documentNodeList,
        documentFileList,
        documentNumber,
        receiverCompany,
        draftCompany,
        mainFile,
        templateId,
        documentType,
        content,
        serialNumber,
      } = info
      const form = {
        lwsj: acceptTime,
        name,
        jjcd: urgencyDegree ? String(urgencyDegree) : '',
        miji: secrecyDegree ? String(secrecyDegree) : '',
        nbyj: deviseOpinion,
        receiverCompany,
        draftCompany: this.formatDraftId(draftCompany),
        draftId: this.formatDraftId(draftId),
        // spfs: '1' || String(approvalWay),
        wjbt: title,
        list: nodeParam,
        nodes: documentNodeList,
        tellPhone,
        attach: mainFile && [mainFile], //正文
        fileIds: documentFileList, //附件
        summary,
        documentNumber,
        documentType,
        templateId,
        serialNumber,
        content,
      }
      this.jbr_list = this.formatDraftId(draftId)
      //
      my_data = form
      //
      list = documentNodeList.slice(0, documentNodeList.length - 1)
      this.resList = documentNodeList.find((j) => j.sort === 100)
      return { my_data, list }
    },
  },
}
