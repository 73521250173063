//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { documentBillDetail } from '@/api/modular/fileStream/documentManage'
import mytable from '@/views/fileStream/documentApproval/documentManage/table.vue'
import editor from '@/views/fileStream/documentManage/editor'
import upload from '@/views/fileStream/documentManage/upload'
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'
import billTable from '@/views/fileStream/documentSend/components/billTable.vue'
import fastTable from '@/views/fileStream/documentSend/components/fastTable.vue'
import myform from './form.vue'
export default {
    components: { mytable, myform, upload, editor, billTable, fastTable },
    data() {
        return {
            fileIds: [],
            content: '',
            info: {},
            billInfo: {}, // 擬稿發文數據
            params: {},
            billMess: {},
            type: 1,
        }
    },
    created() {
        init_wx()
        this.get_info()
    },
    methods: {
        get_info() {
            const { id, type } = this.$route.query
            if (type) {
                this.type = type
            }
            this.params = this.$route.query
            if (id) {
                documentBillDetail({ id }).then((res) => {
                    const { data } = res
                    if (!data) return
                    this.info = data
                    this.billInfo = {
                        ...data,
                        jjcd: data.urgencyDegree,
                        miji: data.secrecyDegree,
                        userId: data.draftId,
                        xzfn: String(data.fileType),
                        fileIds: data.documentFileList,
                    }
                    const { content, documentNodeList, documentFileList, approvalStatus } = res.data
                    this.content = content
                    this.fileIds = documentFileList
                    this.billMess = {
                        documentNodeList,
                        approvalStatus,
                    }
                })
            }
        },
    },
}
